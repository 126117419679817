<template>
    <div class="content-main">
        <div v-if="!errorShow" >
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" >
                <div class="operation-btn-view">
                    <div class="select-wrapper">
                        <el-select v-model="listQuery.gradeId " clearable @change="gradeid" placeholder="请选择年级" :style="{'width':'160px'}">
                            <el-option
                                placeholder="年级"
                                v-for="item in deptList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div style="margin-left: 10px;">
                        <el-button type="primary" v-hasPermi="['workSchedule:save']" @click="addSchedule">添加</el-button>
                    </div>
                </div>
                <table-data
                    ref="table"
                    v-loading="loadingTable"
                    :config="table_config"
                    :tableData="table_data"
                    :backgroundHeader="backgroundHeader"
                    :headerColor="headerColor">
                    <template v-slot:operation="slotData">
                        <el-button @click="edit(slotData.data)"  type="danger" v-hasPermi="['workSchedule:edit']" size="mini">编辑</el-button>
                        <el-button @click="del(slotData.data)"  type="danger" v-hasPermi="['workSchedule:del']" size="mini">删除</el-button>
                    </template>
                </table-data>
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                    />
                <dialog-wrapper :dialogObj="addScheduleDialogObj" @handleClose="addScheduleClose">
                <add-schedule
                    ref="addAsset"
                    :rule-form="addScheduleRulesForm"
                    :rules="addScheduleRules"
                    :deptList="deptList"
                    @submit="addScheduleSubmit"
                    @addsubmit="addsubmit"
                    @close="addScheduleClose"
                />
            </dialog-wrapper>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>
<script>
import {
  // 业务组件
  DialogWrapper,
  Pagination,
  // 功能组件
  Error,
  Loading,
} from 'common-local';
// Ajax 请求
import {
  SystemModel
} from '@/models/System.js';
import {
    classScheduleModel
} from '@/models/ClassSchedule.js';
// 添加校历
import TableData from "../Sub/TableData"
import AddSchedule from "./AddSchedule";
import { downloadFile, formatTreeData } from "@/libs/utils";
import onResize from "@/mixins/onResize"

export default {
    mixins: [onResize],
    // name: "List",
    components:{
        TableData,
        DialogWrapper,
        Pagination,
        // 功能组件
        Error,
        Loading,
        AddSchedule,
    },
    data(){
        return{
            loadingTable:false,
            listQuery:{
                gradeId:'',
                schoolId:'',
                pageNum: 1,
                pageRow: 20,
            },
            deptList: [],
            value: '',
             // 添加
             addScheduleDialogObj: {
                title: '',
                dialogVisible: false,
                width: 'auto'
            },
            addScheduleRulesForm:{
                gradeId:'',
                schoolId:'',
                name:'',
                isArrange:'1',
                id:'',
                endTime:'',
                startTime:"",
            },
            addScheduleRules: {
                name: [{ required: true, message: '请输入作息名称', trigger: 'blur' }],
                gradeId: [{ required: true, message: '请选择年级', trigger: 'change' }],
                startTime: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
                endTime: [{ required: true, message: '请选择结束时间', trigger: 'blur' },],
                isArrange: [{ required: true, message: '请选择排课方式', trigger: 'blur' },],
            },
            // 头部筛选
            formData:{
                formInline:{
                    storeName:'',
                },
                btnList:[{
                        type: "primary",
                        text: '添加',
                        fn: 'add'
                    },
                ]
            },
            // 表格配置
            table_config:{
                thead:[
                    {
                        label:"作息名称",
                        prop:"name",
                        align: 'center'
                    },
                    {
                        label:"开始时间",
                        prop:"startTime",
                        align: 'center'
                    },
                    {
                        label:"结束时间",
                        prop:"endTime",
                        labelWidth: '280',
                        align: 'center'
                    },
                    {
                        label:"是否排课",
                        type: 'function',
                        align: "center",
                        labelWidth: '300',
                        align: 'center',
                        callBack(row) {
                            if (row.isArrange == '1') {
                                return '是'
                            } else if (row.isArrange == '0') {
                                return '否'
                            }
                        }
                    },
                    {
                        label:"操作",
                        type:'slot',
                        slotName: 'operation',
                        className: 'text-spacing-reduction'
                    },
                ],
                check: false,
                height: '',
                rowkey: 'id',
                isExpandAll: true,
                leftEmpty:true
            },
            // 表格数据
            table_data: [],
            // 功能组件
            errorShow: false,
            loadingShow: false,
            // 表头
            backgroundHeader: '#FAFBFC',
            // 表头字体
            headerColor: '#595959',
            total:0,
            schoolId:'',
        }
    },
    computed: {
        importUrl() {
            return require('@/assets/images/import.png')
        },
        exportUrl() {
            return require('@/assets/images/export.png')
        }
    },
    created(){
        this.listQuery.schoolId = this.schoolId = this.$store.state.schoolId;
        this.init('created')
        // this.getDeptList()
    },
    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        this.init('activated')
    },
    mounted() {
    },
    methods:{
        /**
         * 初始化
         * getList  初始化列表
         * clickBtn 查询/重置
         */
         async init(key) {
            if (key === 'created') {
                await this.getDeptList()
            }
            if (this.listQuery.gradeId) {
                this.getList()
            }
        },
        /**
         * @Description: 获取年级列表
         * @DoWhat: 年级下拉框
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-11-14 12:59:40
         */
        async getDeptList() {
            this.loadingTable = true;
            await this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.schoolId,
                organType: "3",
            }).then((res) => {
                this.handleRes(res, () => {
                this.deptList = res.data.data.list.map((itm) => ({
                    label: itm.name,
                    value: itm.id,
                }));
                if (this.deptList.length > 0) {
                            const gradeId = this.deptList[0].value
                            this.listQuery.gradeId = gradeId
                            this.deptList.value = gradeId
                        }
                });
            });
        },
        getList(){
            this.loadingTable = true;
            const classSchedule = new classScheduleModel();
            classSchedule.getSchoolSchedule(this.listQuery).then((res) =>{
                this.handleRes(res, () => {
                this.table_data = res.data.data.list
                this.total = res.data.data.totalCount
                })
                this.loadingTable = false;
            })
        },

        gradeid(){
            this.getList()
        },
        /**
         * 操作按钮
         * addSchedule      添加校历
         * edit   编辑
         * addScheduleSubmit    添加/编辑 【确定】
         * del      删除
         */
         addSchedule(){
            this.addScheduleDialogObj.title = '添加'
            this.addScheduleDialogObj.dialogVisible = true
         },
        /**
         * @Description: 编辑作息时间表
         * @DoWhat: 编辑
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-11-14 16:07:57
         * @param {*} data
         */
        edit(data){
            console.log(data,'编辑的数据');
            this.addScheduleRulesForm.name = data.name
            this.addScheduleRulesForm.gradeId = data.gradeId
            this.addScheduleRulesForm.isArrange = String(data.isArrange)
            console.log(this.addScheduleRulesForm.isArrange,'0000');
            this.addScheduleRulesForm.endTime = data.endTime
            this.addScheduleRulesForm.startTime = data.startTime
            this.addScheduleRulesForm.id = data.id

            this.addScheduleDialogObj.title = '编辑'
            this.addScheduleDialogObj.dialogVisible = true
        },
        /**
         * @Description: 提交添加/编辑的时间表
         * @DoWhat: 添加/编辑--确定
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-11-14 16:08:18
         */
        addScheduleSubmit(){
            this.addScheduleRulesForm.schoolId = this.schoolId;
            const classscheduleModel = new classScheduleModel();
            classscheduleModel.getSchoolScheduleSave(this.addScheduleRulesForm).then((res) =>{
                if (res.data.code === '200') {
                    this.$message.success(this.addScheduleDialogObj.title + '成功')
                    this.getList()
                    this.addScheduleClose()
                } else if(res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 添加弹框-继续添加按钮
         * @DoWhat: 继续添加
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-11-14 16:32:37
         */
        addsubmit(){
            this.addScheduleRulesForm.schoolId = this.schoolId;
            const classscheduleModel = new classScheduleModel();
            classscheduleModel.getSchoolScheduleSave(this.addScheduleRulesForm).then((res) =>{
                if (res.data.code === '200') {
                    this.$message.success(this.addScheduleDialogObj.title + '成功')
                    this.getList()
                    this.addScheduleRulesForm = this.$options.data().addScheduleRulesForm
                    this.$refs.addAsset.$refs.formRef.resetFields()
                    // this.addScheduleClose()
                } else if(res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })

        },
        /**
         * @Description: 取消弹框
         * @DoWhat: 取消
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-11-14 16:09:13
         */
        addScheduleClose(){
            this.addScheduleRulesForm = this.$options.data().addScheduleRulesForm
            this.$refs.addAsset.$refs.formRef.resetFields()
            this.addScheduleDialogObj.dialogVisible = false
        },
        /**
         * @Description: 列表页面删除按钮
         * @DoWhat: 删除
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-11-14 16:30:09
         */
        del(data){
            this.$confirm('确定删除该条作息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const param = {
                    id: data.id,
                }
                this._fet('/school/schoolRestTime/delete', param).then((res) => {
                    this.handleRes(res, () => {
                        this.$message.success('删除成功!')
                        this.getList()
                    })
                })
            }).catch(() => {
            })
        },
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },

    }
    }
</script>
<style  lang="scss" scoped>
.operation-btn-view{
    display: flex;
    padding: 10px 20px;
    align-items: center;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
    .select-wrapper {
        height: 32px;
        /deep/ .el-input__inner {
            background: #F5F5F5;
            border-radius: 4px;
            border: none;
        }

    }
}
.title{
    width: 100%;
    height: 20px;
     background-color: #ffffff;
     padding:20px;
     font-size: 20px;
     color: red;
     font-weight: bold;
     margin-bottom:2px;
}
</style>
