<template>
    <el-form class="common-form" :model="ruleForm" :rules="rules" label-width="auto" ref="formRef">
        <el-form-item label="年级" prop="gradeId">
            <el-select v-model="ruleForm.gradeId " clearable placeholder="请选择" class="input-width-medium">
                <el-option
                    v-for="item in deptList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="作息名称" prop="name">
            <el-input
                v-model="ruleForm.name"
                placeholder="请输入作息名称"
                clearable
                maxlength="10"
                show-word-limit
                class="input-width-medium"
            ></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="startTime">
            <div class="date_box">
                <el-time-picker
                    v-model="ruleForm.startTime"
                    format='HH:mm'
                    value-format="HH:mm"
                    class="input-width-medium"
                    :picker-options="{
                        selectableRange:`00:00:00 -${ruleForm.endTime ? ruleForm.endTime +':00' : '23:59:00'}`
                    }">
                </el-time-picker>
            </div>
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
            <div class="date_box">
                <el-time-picker
                    v-model="ruleForm.endTime"
                    format='HH:mm'
                    value-format="HH:mm"
                    class="input-width-medium"
                    :picker-options="{
                        selectableRange:`${ruleForm.startTime ? ruleForm.startTime +':00' : '00:00:00'}-23:59:00`
                    }">
                </el-time-picker>
            </div>
        </el-form-item>
        <el-form-item label="是否排课" prop="isArrange">
            <el-radio-group v-model="ruleForm.isArrange">
                <el-radio  label='1'>是</el-radio>
                <el-radio  label='0'>否</el-radio>
            </el-radio-group>
        </el-form-item>

        <div style="text-align: right">
            <xk-button @click="handleClose" type="info">取消</xk-button>
            <xk-button @click="addsubmitForm" type="primary">继续添加</xk-button>
            <xk-button @click="submitForm" type="primary">确定</xk-button>
        </div>
    </el-form>
</template>

<script>
export default {
    props: {
        ruleForm: {
            type: Object,
        },
        rules: {
            type: Object
        },
        deptList:{
            type: Array
        }
    },
    methods: {
        submitForm() {
            this.$refs.formRef.validate((val) => {
                if (val) {
                    this.$emit('submit')
                }
            })
        },
        addsubmitForm() {
            this.$refs.formRef.validate((val) => {
                if (val) {
                    this.$emit('addsubmit')
                }
            })
        },
        handleClose() {
            this.$emit('close')
        },
    }
}
</script>

<style scoped lang="scss">
</style>
